.modal-custom {
    display: block;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
    top: 0;
    left: 0;
    z-index: 10000;
    overflow-x: hidden;
    background-color: rgba(31, 32, 41, 0.75);
    pointer-events: none;
    opacity: 0;
    transition: opacity 250ms 700ms ease;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    position: fixed; /* Fix position on the top-left corner*/
    top: 0;
    left: 0;
    overflow: auto; /* Enable scroll if needed */
    /* padding-top: 80px; */ /* Location of the content container */
  }
  
  .visible {
    pointer-events: auto;
    opacity: 1;
    transition: all 300ms ease-in-out;
  }
  
  .modal__wrap {
    /* overflow-y: scroll; */
    /* overflow-x: hidden; */
    position: relative;
    display: block;
    /* width: 60vw;
    height: 60%; */
    /* min-height: 400px;
    min-width: 400px; */
    /* margin: 0 auto; */
    /* margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 4px; */
    /* padding-bottom: 20px; */
    background-color: #fff;
    /* align-self: center; */
    box-shadow: 0 12px 25px 0 rgba(199, 175, 189, 0.25);
    opacity: 0;
    transform: scale(0.6);
    transition: opacity 250ms 250ms ease, transform 300ms 250ms ease;
    transform: scale(0);

    width: 70%;
    /* max-width: 640px; */
    height: 90%;
    margin: auto;
    padding: 10px;
    /* border: 1px solid black; */
    border-radius: 5px;
    /* overflow: scroll; */
  }

  .visible .modal__wrap {
    opacity: 1;
    transform: scale(1);
    transition: opacity 250ms 500ms ease, transform 350ms 500ms ease;
    background-color: #2A3038;
  }

@media screen and (max-width: 445px) {
  .modal__wrap {
    width: 85%;
  }
}
  