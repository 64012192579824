/*Template Category*/
.t-categories .t-category {
	float: left;
	/* min-width: 50px; */
	min-width: 15%;
    text-align: center;
	margin: 0 !important;
	min-height: 35px;
}
.t-categories .wide-category {
	min-width: 18% !important;
}

@media screen and (max-width: 1125px) {
	.t-categories .t-category {
		min-width: 18%;
	}
	.t-categories .wide-category {
		min-width: 28% !important;
	}
}
@media screen and (max-width: 768px) {
	.template-search-div {
		width: 100%;
	}
}
@media screen and (max-width: 730px) {
	.t-categories .t-category {
		min-width: 17%;
	}
	.t-categories .wide-category {
		min-width: 25% !important;
	}
	.t-categories .t-category a {
		padding: 10px 15px !important;
	}
	.t-categories .t-category a h5 {
		font-size: 10px !important;
	}
}
@media screen and (max-width: 650px) {
	.t-categories .t-category {
		min-width: 30%;
	}
	.t-categories .wide-category {
		min-width: 35% !important;
	}
	.t-categories .t-category a h5 {
		font-size: 12px !important;
	}
}

@media screen and (max-width: 515px) {
	.t-categories .t-category {
		min-width: 40%;
	}
	.t-categories .wide-category {
		min-width: 55% !important;
	}
}
.t-categories .t-category a {
	display: block;
	background-color: #ff0066;
	padding: 10px 25px;
	border-radius: 2px;
	margin-right: 20px;
}
.t-categories .t-category a h5 {
	font-size: .8rem;
	color: #6c728c;
	padding: 0;
	margin: 0;
}
.t-categories .t-category a.active {
	background-color: #ff0066;
}
.t-categories .t-category a.active h5 {
	font-weight: bold;
    color: #ffffff;
}

.inactive-badge {
    color: #777171;
    border: 1px solid #777171;
}

.scroll-div {
    display: flex;
    align-items: center;
}

.left-and-right-t-category {
    cursor: pointer;
    display: inline-block;
    font-size: 32px;
}

.templates-div {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin: 5% 0;

    
    @media screen and (max-width: 500px) {
        flex-direction: column;

        .template {
            width: 100%;
        }
    }
}

.template {
    position: relative;
    flex-basis: 31.3%;
    margin-bottom: 50px;
    width: 31%;
    margin-right: 3%;

    &:nth-child(3n+3) {
        margin-right: 0;
    }

    &:hover {

        .template-drop-down {
            display: block;
        }
    }

    .template-body {
        
        @media screen and (max-width: 1125px) {
            height: 20vh;
        }
    }
}


@media only screen and (max-width: 950px) and (min-width: 600px) {
    .template {
        flex-basis: 23.5%;
        width: 23.5%;
    }
}

.template-drop-down {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 10;
    display: none;

    .template-caret {        
        background-color: #0f1015;
        border: 1px solid #0f1015;
    }
}

// @media only screen and (min-width: 950px) {
//     .template {
//         flex-basis: 30%;
//         width: 30%;
//         margin-right: 2%;
//     }
// }

.template-footer-inner-div {
    display: flex;
    align-items: center;
}

.template-footer-span-label {
    display: inline-block;
    margin-left: 8%;
    color: #007bff;

    &:hover {
        color: #ffc107;
    }
}

.template-preview-div {
    
    img {
        width: 100%;
        object-fit: cover;
        height: 200px;
    }
}

.dont-click {
    cursor: not-allowed;
	pointer-events: none;
}

.preview_img {
    box-shadow: 0 4px 8px rgba(9, 30, 66, .25);
    width: 100%; 
    max-width: 700px;
    border-radius: 5px;
}

.owner_img {
	margin: 0 0 10px;
	width: 30%;
	min-width: 35px;
	max-width: 75px;
	position: relative;
	z-index: 1;
	border-radius: 50%;
	background: #fff;
	border: 3px solid #fff;
	box-shadow: 0 1px 1px rgba(9, 45, 66, .25);
}

.template-btn-div {
    display: flex;
    justify-content: space-between;

    button {
        width: 45%;
    }
}

.edit-template-select {
    color: #0f1015;
}

.edit-template-form-control {
    border: 1px solid #6c728c;
}

.css-1uccc91-singleValue {
    color: #ffffff !important;
}

.tags-input {
    display: inline-block;
    // background-color: #fff;
    background-color: #2A3038;
    border: 1px solid #6c728c;
    background-clip: padding-box;
    width: 100%;
    cursor: text;
    height: fit-content;
    border-radius: .25rem;
    box-shadow: inset 0 0 0 transparent;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;    
    width: 80%;
    margin-left: 1.6%;
}

.tags-input .main-input {
    border: 0;
    outline: 0;
    padding: 0.5em 0.1em;
    background-color: #2A3038;
    /* width: 100%; */
    /* height: 100%; */
}

.swal2-container {
    z-index: 1000000 !important;
}




.tag-like-elem {
	/* background-color: #4A9DFF;
	color: #ffffff;
	margin-left: 5%;
	margin-top: 5%;
	margin-bottom: 5%;
	border-radius: 2px; */
	font-size: 85%;
	padding: 5px 25px 5px 15px;
	width: fit-content;
    margin: 0.6em 0.5em;
	background-color: #ced4da;
	border-radius: 3px;
    color: black;
    transition: all 0.1s linear;
    cursor: pointer;
	display: inline-block;
	position: relative;
}
.tag-like-elem:hover {
	background-color: #ffc107;
    color: white;
}
  
.tag-like-content {
	display: inline-block;
}
  
.tag-like-elem-close {
	display: inline-block;
	cursor: pointer;
	position: absolute;
	right: 5%;
}

.tag-like-elem-close:hover::after {
    color: black;
}

.tag-like-elem-close::after {
    content: '×';
    font-weight: bold;
    display: inline-block;
    transform: scale(1.4);
    margin-left: -1em;
	color: red;
}

.dont-click {
    cursor: not-allowed;
}

.you-can-click {
    cursor: pointer;
}

.custom-link:hover {
    text-decoration: none;
    color: #ffc107;
}

.campaign-cate {
    background: #191c24;
    color: #ffffff;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.sort-select {
    cursor: pointer;
}

.page-numbers {
    list-style: none;
    display: flex;
    margin-top: 5%;
    justify-content: center;

    li {
        padding: 10px;
        border: 1px solid #58f195;
        cursor: pointer;
        background-color: #58f195;
        border-right: 1px solid #ffffff;

        button {
            background-color: transparent;
            border: none;
            color: #ffffff;
            cursor: pointer;
        
            &:hover {
                background-color: #ffffff;
                color: #0f1015;
            }
        
            &:focus {
                outline: none;
            }
        }
    }

    li.page-active-now {
        background-color: #ffffff;
        color: #0f1015;
    }
}

.admin-edit-del-btn {
    width: 45%;
}

thead tr {
    background-color: #58f195;
}

.hide {
    display: none;
}