.categories-div {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    margin: 5% 0;

    @media screen and (max-width: 500px) {
        flex-direction: column;

        .category-component {
            width: 100%;
        }
    }
}

.input-image {
    display: none;
}

.image-div {
	position: relative;
	width: 150px;
}

.image {
	width: 100%;
    height: 150px;
}

.image {
	border: 3px solid #adb5bd;
    margin: 0 auto;
    // padding: 3px;
}

.no-image-selected {
	position: absolute;
    font-size: 11px;
    left: 5%;
    font-weight: bold;
    top: 46%;
    text-align: center;
    color: #000000;
}

.choose-btn {
    background-color: #2A3038;
}

.btn {
	margin-bottom: 20px;
	font-size: 14px;
	font-weight: 700;
}

.input-image-label {
	margin-bottom: 0;
    margin-left: 2.2%;
    margin-top: 5%;
}

.category-component {
    position: relative;
    flex-basis: 31.3%;
    margin-bottom: 50px;
    width: 31%;
    margin-right: 3%;

    &:nth-child(3n+3) {
        margin-right: 0;
    }

    .category-component-img-div {

        img {width: 100%;
            object-fit: cover;
            height: 200px;
        }
    }

    // .category-body {
        
    //     @media screen and (max-width: 1125px) {
    //         height: 20vh;
    //     }
    // }
}


@media only screen and (max-width: 950px) and (min-width: 600px) {
    .category-component {
        flex-basis: 23.5%;
        width: 23.5%;
    }
}