.spm-hourglass {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}

.spm-hourglass:after {
  content: " ";
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 12px solid #fc424a;
  border-color: #fc424a transparent #fc424a transparent;
  animation: spm-hourglass 1.2s infinite;
}

@keyframes spm-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  100% {
    transform: rotate(1800deg);
  }
}
  